import React, {useContext} from "react"
import { ConfiguratorContext } from "context/configuratorContext"
import { Table } from "reactstrap"
import MiFallbackImage from "../../../../components/Common/mi/MiFallbackImage"

function TableListOfferFree({ offerItems, offerData, totalPrice, showTotal }) {
  const { allFormulas } = useContext(ConfiguratorContext)

  let data = Object.entries(offerItems)
  data.sort((a, b) => {
    
    if (a[0] === allFormulas.machineCode) {
      return -1
    }
    if (b[0] === allFormulas.machineCode) {
      return 1
    }
    return 0
  })
  return (
    <>
      <h3 className="mt-5">Lista a confezione</h3>
      <Table id="table-free" striped bordered hover className="mt-2 mb-0" responsive>
        <thead>
          <tr>
            <th width={140}>CODICE</th>
            <th width={100}>IMG</th>
            <th>DESCRIZIONE</th>
            <th width={100} style={{ textTransform: "uppercase" }}>quantità a offerta</th>
            <th width={100} style={{ textTransform: "uppercase" }}>
              quantità per confezione
            </th>
            <th width={100} style={{ textTransform: "uppercase" }}>numero confezioni</th>
            <th width={100} style={{ textTransform: "uppercase" }}>
              prezzo per confezione
            </th>
            <th width={100} style={{ textTransform: "uppercase" }}>
              prezzo totale
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map(([code, item], i) => (
            <>
              {code !== "error" ? (
                <tr key={"row-item-" + i + "-" + code}>
                  <td>{code}</td>
                  <td><MiFallbackImage height="70" src={'/imgs/pdfs/products/'+ code + '.jpg'} /></td>
                  <td>{item.package_name}</td>
                  <td>{(offerData.data[code] || 1) + " " + item.measure_unit}</td>
                  <td>{item.quantity_per_package + " " + item.measure_unit}</td>
                  <td>{item.quantity}</td>
                  <td>{Number(item.package_price).toFixed(2)} €</td>
                  <td>{Number(item.package_price * item.quantity).toFixed(2)} €</td>
                </tr>
              ) : (
                <></>
              )}
            </>
          ))}
          {
            showTotal && <tr>
              <td colSpan={4} className="text-right">Totale</td>
              <td>{Number(totalPrice).toFixed(2)} €</td>
            </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default TableListOfferFree

import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Col, 
  Row, 
  CardBody, 
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardText,
  Label,
  FormGroup,
  Form,
  Input,
  Alert

} from 'reactstrap'
import Select from "react-select"
import { generateNumberArray } from "helpers/utils"
import { MiTooltip } from 'components/Common/mi/MiTooltip'
import { ConfiguratorContext } from 'context/configuratorContext'

function ConfiguratorStep2({step}) {

  let inputValidation = useRef(null)

  const { configuratorData, setConfiguratorData, validations, setValidations } = useContext(ConfiguratorContext);
  const defaultData = configuratorData && configuratorData['stepData' + step] ? configuratorData['stepData' + step] : {}

  const checkValidation = () => {
    inputValidation= [...document.querySelectorAll('input[type="number"]:invalid')]
    if (validations && validations['stepData2']) {
      validations['stepData2'].isValid = (selectedReflow && selectedOccupancy && selectedAirPerOccupant && selectedMaxSpeedDistributionPlenum && selectedMaxSpeedDistribution && inputValidation.length === 0)
      setValidations({ ...validations })
    } else {
      setValidations({ ...validations, ['stepData2']: { isValid: (selectedReflow && selectedOccupancy && selectedAirPerOccupant && selectedMaxSpeedDistributionPlenum && selectedMaxSpeedDistribution && inputValidation.length === 0) } })
    }
    // console.log('validations', selectedReflow, selectedOccupancy, selectedAirPerOccupant, selectedMaxSpeedDistributionPlenum, selectedMaxSpeedDistribution, inputValidation.length === 0)
  }

  const [selectedReflow, setSelectedReflow] = useState(defaultData?.selectedReflow || null)
  const [selectedOccupancy, setSelectedOccupancy] = useState(defaultData?.selectedOccupancy || null)
  const [selectedAirPerOccupant, setSelectedAirPerOccupant] = useState(defaultData?.selectedAirPerOccupant || null)
  const [selectedMaxSpeedDistributionPlenum, setSelectedMaxSpeedDistributionPlenum] = useState(defaultData?.selectedMaxSpeedDistributionPlenum || null)
  const [selectedMaxSpeedDistribution, setSelectedMaxSpeedDistribution] = useState(defaultData?.selectedMaxSpeedDistribution || null)



  const optionGroup = generateNumberArray(0.1, 5.0, { integersOnly: false, decimalPlaces: 1 })
  const occupancyGroup = generateNumberArray(1, 10, { integersOnly: true, decimalPlaces: 0 }, true)
  const airPerOccupantGroup = generateNumberArray(10.0, 100.0, { integersOnly: false, decimalPlaces: 1 })
  const MaxSpeedDistributionPlenumGroup = generateNumberArray(0.1, 15.0, { integersOnly: false, decimalPlaces: 1 })
  const MaxSpeedDistributionGroup = generateNumberArray(0.1, 10.0, { integersOnly: false, decimalPlaces: 1 })

  useEffect(() => {
    setSelectedReflow(defaultData?.selectedReflow || optionGroup[4])
    setSelectedAirPerOccupant(defaultData?.selectedAirPerOccupant || 39.6)
    setSelectedMaxSpeedDistributionPlenum(defaultData?.selectedMaxSpeedDistributionPlenum || MaxSpeedDistributionPlenumGroup[74])
    setSelectedMaxSpeedDistribution(defaultData?.selectedMaxSpeedDistribution || MaxSpeedDistributionGroup[29])
  }, [])

  useEffect(() => {
    
    if (configuratorData) {
      configuratorData['stepData2'] = {
        selectedReflow,
        selectedOccupancy,
        selectedAirPerOccupant,
        selectedMaxSpeedDistributionPlenum,
        selectedMaxSpeedDistribution
      }
      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
  }, [
    selectedReflow, selectedOccupancy, selectedAirPerOccupant, selectedMaxSpeedDistributionPlenum, selectedMaxSpeedDistribution
  ]);




  return (
    <Row>
      <Col lg={6}>
        <Card className='no-shadow'>
        <CardHeader className='p-3 rounded-xl'>

          <CardImg
            alt="Sample"
            className='no-rounded'
            src="/imgs/Step-2.gif"
          />
        </CardHeader>
          <CardBody>
            <CardTitle tag="h5">
              STEP 2
            </CardTitle>
            <CardText>
              In questa schermata vengono selezionati i dati progettuali del sistema.
              Il calcolo della portata aria necessaria da introdurre nei locali sarà basato sul «coefficiente di ricambio» aria che è a discrezione dell’utente. 
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
          <div className="mt-3 mb-3">
            <Form>
              <Card>
                <CardBody>
                  <h3 className='mb-4'>Parametri progetto</h3>
                  <FormGroup className='mb-5'>
                    <Label className='mb-1 d-block'>
                      Tasso di ricambio aria [Vol/h]
                      <MiTooltip 
                        name={'reflow'} 
                        message={`Tipicamente i sistemi VMC in Italia vengono dimensionati con un tasso di ricambio orario pari a 0,5 Vol/h garantendo in questo modo un totale rinnovo dell'aria ambiente ogni 2 ore.`}
                      />
                      
                    </Label>
                    <Input
                      className='mb-4'
                      name="reflowValue"
                      type="number"
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      value={selectedReflow || ''}
                      onChange={value =>
                        setSelectedReflow(value.target.value)
                      }
                      step={0.01}
                      min={optionGroup[0]}
                      max={optionGroup[optionGroup.length - 1]}
                    />
                    { selectedReflow && (selectedReflow < optionGroup[0] || selectedReflow > optionGroup[optionGroup.length - 1]) &&
                      <Alert 
                      color="warning" 
                      className='mt-3'
                    >
                      Inserisci un valore compreso tra {airPerOccupantGroup[0]} e {airPerOccupantGroup[airPerOccupantGroup.length - 1]}
                    </Alert>}
                    <Label className='mb-1 d-block'>Numero di occupanti </Label>
                    <Select 
                      placeholder="Seleziona..."
                      className="mb-4"
                      options={occupancyGroup}
                      value={selectedOccupancy}
                      onChange={(value) => {
                        setSelectedOccupancy(value)
                      }}
                      classNamePrefix="select2-selection"
                    />
                    <Label className='mb-1 d-block'>Portata di aria per occupante [m3/h] 
                      <MiTooltip 
                        name={'reflowPerOccupantValue'} 
                        message={`L'approccio di calcolo della portata di aria necessaria alla ventilazione secondo la norma UNI 10339 si basa sul numero di occupanti e su un apporto di 39,6 m3/h per ogni persona che occupa la casa.`}
                      />
                    </Label>
                    <Input
                      className='mb-4'
                      name="reflowPerOccupantValue"
                      type="number"
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      value={selectedAirPerOccupant || ''}
                      step={0.1}
                      onChange={value =>
                        setSelectedAirPerOccupant(value.target.value)
                      }
                      min={airPerOccupantGroup[0]}
                      max={airPerOccupantGroup[airPerOccupantGroup.length - 1]}
                    />
                    { selectedAirPerOccupant && (selectedAirPerOccupant < airPerOccupantGroup[0] || selectedAirPerOccupant > airPerOccupantGroup[airPerOccupantGroup.length - 1]) &&
                      <Alert 
                      color="warning" 
                      className='mt-3'
                    >
                      Inserisci un valore compreso tra {airPerOccupantGroup[0]} e {airPerOccupantGroup[airPerOccupantGroup.length - 1]}
                    </Alert>}
                  </FormGroup>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h3 className='mb-4'>Velocità limite </h3>
                  <FormGroup className='mb-3'>
                    <Label className='mb-1 d-block'>Velocità limite tubi prima del plenum di distribuzione (m/s) <span className='badge'>A</span>
                      <MiTooltip 
                        name={'maxSpeedDistributionPlenum'} 
                        message={`Per garantire un elevato livello di comfort acustico si suggerisce di mantenere la velocità dell'aria all'interno dei tubi prima del plenum di distribuzione a valori inferiori a 7,5 m/s.`}
                      />
                    </Label>
                    <Input
                      className='mb-4'
                      name="maxSpeedDistributionPlenum"
                      type="number"
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      value={selectedMaxSpeedDistributionPlenum || ''}
                      step={0.1}
                      onChange={value =>
                        setSelectedMaxSpeedDistributionPlenum(value.target.value)
                      }
                      min={MaxSpeedDistributionPlenumGroup[0]}
                      max={MaxSpeedDistributionPlenumGroup[MaxSpeedDistributionPlenumGroup.length - 1]}
                    />
                    { selectedMaxSpeedDistributionPlenum && (selectedMaxSpeedDistributionPlenum < MaxSpeedDistributionPlenumGroup[0] || selectedMaxSpeedDistributionPlenum > MaxSpeedDistributionPlenumGroup[MaxSpeedDistributionPlenumGroup.length - 1]) &&
                      <Alert 
                      color="warning" 
                      className='mt-3'
                    >
                      Inserisci un valore compreso tra {MaxSpeedDistributionPlenumGroup[0]} e {MaxSpeedDistributionPlenumGroup[MaxSpeedDistributionPlenumGroup.length - 1]}
                    </Alert>}
                    <Label className='d-block mb-1'>Velocità limite tubi di distribuzione (m/s) <span className='badge'>B</span>
                      <MiTooltip 
                        name={'MaxSpeedDistribution'} 
                        message={`Per mantenere un comfort abitativo ottimale si raccomanda di mantenere la velocità dell'aria all'interno di ogni singolo tubo di distribuzione sotto i 3 m/s.`}
                      />
                    </Label>
                    <Input
                      className='mb-4'
                      name="MaxSpeedDistribution"
                      type="number"
                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                      value={selectedMaxSpeedDistribution || ''}
                      step={0.1}
                      onChange={value =>
                        setSelectedMaxSpeedDistribution(value.target.value)
                      }
                      min={MaxSpeedDistributionGroup[0]}
                      max={MaxSpeedDistributionGroup[MaxSpeedDistributionGroup.length - 1]}
                    />
                    { selectedMaxSpeedDistribution && (selectedMaxSpeedDistribution < MaxSpeedDistributionGroup[0] || selectedMaxSpeedDistribution > MaxSpeedDistributionGroup[MaxSpeedDistributionGroup.length - 1]) &&
                      <Alert 
                      color="warning" 
                      className='mt-3'
                    >
                      Inserisci un valore compreso tra {MaxSpeedDistributionGroup[0]} e {MaxSpeedDistributionGroup[MaxSpeedDistributionGroup.length - 1]}
                    </Alert>}
                  </FormGroup>
                </CardBody>
              </Card>
            </Form>
          </div>
      </Col>
    </Row>
  )
}

export default ConfiguratorStep2
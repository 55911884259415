import React from "react"
import { Table } from "reactstrap"
import MiFallbackImage from "../../../../components/Common/mi/MiFallbackImage"

function TableListOfferKit({ allFormulas, offerItems, offerItemsDetails, showTotal,totalPrice }) {
  const getDescription = (code) => {
    
    const item = offerItemsDetails.find((item) => {
      return item.code === code
    })
    return item ? item.name : ""
  }
  const getUnitMeasure = (code) => {
    const item = offerItemsDetails.find((item) => item.code === code)
    return item ? item.measure_unit : ""
  }

  const getMachinePrice = (code) => {
    const item = offerItemsDetails.find((item) => item.code === code)
    return Number(item?.price ? item.price : item?.package_price).toFixed(2)
  }
  return (
    <>
      <h3 className="mt-5">Lista a kit</h3>
      {allFormulas && offerItemsDetails && <Table id="table-kit" striped bordered hover className="mt-2 mb-0 position-relative" style={{zIndex:100}} responsive>
        <thead>
          <tr>
            <th width="140">nome</th>
            <th style={{ textTransform: "uppercase" }}>descrizione</th>
            <th width="100" style={{ textTransform: "uppercase" }}>numero kit</th>
            <th width="100" style={{ textTransform: "uppercase" }}>prezzo a kit</th>
            <th width="100" style={{ textTransform: "uppercase" }}>prezzo totale</th>
          </tr>
        </thead>
        <tbody>
          {allFormulas && allFormulas.machineCode &&
            <tr>
              <td>
                {allFormulas.machineCode}
              </td>
              <td className="p-0">
                <Table striped bordered width={"100%"} className="m-0">
                  <tbody>
                    <tr>
                      <td width={100}>
                      <MiFallbackImage src={'/imgs/pdfs/products/'+ allFormulas.machineCode + '.jpg'} height="70" />  
                      </td>
                      <td>
                        {getDescription(allFormulas.machineCode)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
                1 
              </td>
              <td>
              {getMachinePrice(allFormulas.machineCode)} €
              </td>
              <td>
              {getMachinePrice(allFormulas.machineCode)} €
              </td>

            </tr>
          }
          {Object.entries(offerItems).map(([code, item], i) => (
            <>
              {code !== "error" ? (
                <tr key={"row-item-" + i + "-" + code}>
                  <td>{item.name}</td>

                  <td className="p-0">
                    <Table striped bordered width={"100%"} className="m-0">
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "100px",
                            }}
                          >
                            img
                          </th>
                          <th
                            style={{
                              width: "100px",
                            }}
                          >
                            codice
                          </th>
                          <th
                            // style={{
                            //   width: "80",
                            // }}
                          >
                            nome
                          </th>
                          <th
                            style={{
                              width: "100px",
                            }}
                          >
                            quantità nel kit
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(item.components).map(
                          ([codeComponent, product], x) => (
                            <tr key={"row-product-" + x + "-" + codeComponent}>
                              <td width={100}>
                                <MiFallbackImage src={'/imgs/pdfs/products/'+ codeComponent + '.jpg'} height="70" />  
                              </td>
                              <td width={100}>{codeComponent}</td>
                              <td>
                                {product.reference_codes
                                  ? product.reference_codes.join(", ")
                                  : product.package_name}
                              </td>
                              <td width={100}>
                                {product.quantity_per_package}{" "}
                                {product.measure_unit}
                              </td>
                              
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                  <td>{item.quantity}</td>
                  <td>{item.price} €</td>
                  <td>{Number(item.price * item.quantity).toFixed(2)} €</td>
                </tr>
              ) : (
                <></>
              )}
            </>
          ))}
          {
            showTotal && 
            <tr>
              <td colSpan={4} className="text-right">Totale</td>
              
              <td>{Number(totalPrice).toFixed(2)} €</td>
            </tr>
          }
        </tbody>
      </Table>}
    </>
  )
}

export default TableListOfferKit

import React, { useState, useEffect, useContext, useRef } from "react"
import {
  Col,
  Row,
  CardBody,
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardText,
  Input,
  Label,
  FormGroup,
  Alert
} from "reactstrap"
//Import Flatepicker
import Select from "react-select"
import { ConfiguratorContext } from "context/configuratorContext"
import { MiModal } from "components/Common/mi/MiModal"

function ConfiguratorStep7({ setupData, step }) {
  const { configuratorData, setConfiguratorData, validations, setValidations, isKit } =
    useContext(ConfiguratorContext)
  const defaultData =
    configuratorData && configuratorData["stepData" + step]
      ? configuratorData["stepData" + step]
      : {}

  const [showModal, setShowModal] = useState(false)
  const [tmpPipe, setTmpPipe] = useState(null)
  const [selectedPipe, setSelectedPipe] = useState(
    defaultData?.selectedPipe || { mandata: null, estrazione: null }
  )
  const [selectedPipeLength, setSelectedPipeLength] = useState(
    defaultData?.selectedPipeLength || { mandata: null, estrazione: null }
  )
  const [selectedNumPipeCurve, setSelectedNumPipeCurve] = useState(
    defaultData?.selectedNumPipeCurve || { mandata: null, estrazione: null }
  )

  const toggle = () => setShowModal(!showModal)

  const handleMultipleSelectionTrigger = () => {
    // console.log('handleMultipleSelectionTrigger', tmpPipe)
    // for current step
    const pipeValue = selectedPipe || {}
    pipeValue['mandata'] = tmpPipe
    pipeValue['estrazione'] = tmpPipe
    setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))

    // for next step
    const dataPrevStep = configuratorData["stepData" + (Number(step) - 1)]
    const nextPipeValue = dataPrevStep?.selectedPipe || {}
    nextPipeValue['mandata'] = tmpPipe
    nextPipeValue['estrazione'] = tmpPipe
    dataPrevStep.selectedPipe = JSON.parse(JSON.stringify(nextPipeValue))
    configuratorData["stepData" + (Number(step) - 1)] = dataPrevStep
    setConfiguratorData({ ...configuratorData })
    setTmpPipe(null)
    toggle()
  }

  const getPipeOptions = () => {
    return [
      { value: "EPE", label: "EPE" },
      { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" },
    ]
  }

  useEffect(() => {
    if(isKit) {

      setSelectedPipe({ mandata: { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" }, estrazione: { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" } }) 
    }
  }, [isKit])

  let inputValidation = useRef(null)
  const checkValidation = () => {
    inputValidation= [...document.querySelectorAll('input[type="number"]:invalid')]
    if (validations && validations['stepData' + step]) {
      validations['stepData' + step].isValid = selectedPipe?.mandata && selectedPipe?.estrazione &&
      selectedPipeLength?.mandata && selectedPipeLength?.estrazione &&
      selectedNumPipeCurve?.mandata && selectedNumPipeCurve?.estrazione && inputValidation.length === 0
      setValidations({ ...validations })
    } else {
      setValidations({ ...validations, ['stepData' + step]: { isValid: selectedPipe?.mandata && selectedPipe?.estrazione &&
        selectedPipeLength?.mandata && selectedPipeLength?.estrazione &&
        selectedNumPipeCurve?.mandata && selectedNumPipeCurve?.estrazione && inputValidation.length === 0} })
    }
  }

  useEffect(() => {
    if (configuratorData) {
      const data = configuratorData["stepData" + step]
      configuratorData["stepData" + step] = {
        ...data,
        selectedPipe,
        selectedPipeLength,
        selectedNumPipeCurve,
      }
      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
  }, [selectedPipe, selectedPipeLength, selectedNumPipeCurve])

  return (
    <Row>
      <Col lg={6}>
        <Card className="no-shadow">
          <CardHeader className="p-3 rounded-xl">
            <CardImg
              alt="Sample"
              className="no-rounded"
              src="/imgs/Step-6.gif"
            />
          </CardHeader>
          <CardBody>
            <CardTitle tag="h5">STEP 6</CardTitle>
            <CardText>
            In questa schermata vengono selezionate le caratteristiche dei condotti principali (tratto di impianto compreso tra l'unità di ventilazione e i BOX di distribuzione).
            </CardText>
          </CardBody>
        </Card>
      </Col>

      <Col lg={6}>
        <h4>UNITA' DI VENTILAZIONE - BOX DI DISTRIBUZIONE</h4>
        <Card>
          <CardBody>
            <h3>
              Condotto di mandata{" "}
              <span className="badge">
                C
              </span>
            </h3>
            <p>
            Condotto di collegamento compreso tra l'unità di ventilazione e il BOX di mandata

            </p>
            <FormGroup>
              <Label for="pipe-type" className="mb-1">Tipologia tubo (mandata)</Label>
              <Select
                placeholder="Seleziona..."  
                className="mb-3"
                isDisabled={isKit}
                name="pipe-type"
                id="pipe-type"
                value={selectedPipe?.mandata}
                onChange={value => {
                  const pipeValue = selectedPipe || {}
                  pipeValue["mandata"] = value
                  setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))
                  setShowModal(true)
                  setTmpPipe(value)
                }}
                options={getPipeOptions()}
              />
            </FormGroup>
            <FormGroup className="">
              <Label for="pipe-length"  className="mb-1">Lunghezza tubo mandata (m)</Label>
              <Input
              className="mb-3"
                name="pipe-length"
                id="pipe-length"
                value={selectedPipeLength?.mandata || ""}
                onChange={e => {
                  const pipeLengthValue = selectedPipeLength || {}
                  pipeLengthValue["mandata"] = e.target.value
                  setSelectedPipeLength(
                    JSON.parse(JSON.stringify(pipeLengthValue))
                  )
                }}
                type="number"
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                step={0.1}
                min={0.1}
                max={100}
              />
              { selectedPipeLength?.mandata && (selectedPipeLength?.mandata < 0.1 || selectedPipeLength?.mandata > 100.0) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0.1 e 100.0
                    </Alert>
                  }
            </FormGroup>
            <FormGroup className="">
              <Label for="pipe-length"  className="mb-1">Numero curvo tratte (mandata)</Label>
              <Input
              className="mb-3"
                name="num-pipe-curve"
                id="num-pipe-curve"
                value={selectedNumPipeCurve?.mandata || ""}
                onChange={e => {
                  const numPipeCurveValue = selectedNumPipeCurve || {}
                  numPipeCurveValue["mandata"] = e.target.value
                  setSelectedNumPipeCurve(
                    JSON.parse(JSON.stringify(numPipeCurveValue))
                  )
                }}
                type="number"
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                min={0}
                max={50}
              />
              { selectedNumPipeCurve?.mandata && (selectedNumPipeCurve?.mandata < 0 || selectedNumPipeCurve?.mandata > 50) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0 e 50
                    </Alert>
                  }
            </FormGroup>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h3>
              Condotto di ripresa{" "}
              <span className="badge">
                D
              </span>
            </h3>
            <p>
            Condotto di collegamento compreso tra l'unità di ventilazione e il BOX di ripresa

            </p>
            <FormGroup>
              <Label for="pipe-type"  className="mb-1">Tipologia tubo (estrazione)</Label>
              <Select
                placeholder="Seleziona..."
                className="mb-3"
                isDisabled={isKit}
                name="pipe-type"
                id="pipe-type"
                value={selectedPipe?.estrazione}
                onChange={value => {
                  const pipeValue = selectedPipe || {}
                  pipeValue["estrazione"] = value
                  setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))
                  setShowModal(true)
                  setTmpPipe(value)
                }}
                options={getPipeOptions()}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pipe-length"  className="mb-1">Lunghezza tubo estrazione (m)</Label>
              <Input
              className="mb-3"
                name="pipe-length"
                id="pipe-length"
                value={selectedPipeLength?.estrazione || ""}
                onChange={e => {
                  const pipeLengthValue = selectedPipeLength || {}
                  pipeLengthValue["estrazione"] = e.target.value
                  setSelectedPipeLength(
                    JSON.parse(JSON.stringify(pipeLengthValue))
                  )
                }}
                type="number"
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                min={0.1}
                step={0.1}
                max={100}
              />
              { selectedPipeLength?.estrazione && (selectedPipeLength?.estrazione < 0.1 || selectedPipeLength?.estrazione > 100) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0.1 e 100
                    </Alert>
                  }
            </FormGroup>
            <FormGroup>
              <Label for="pipe-length"  className="mb-1">Numero Curve tratte (estrazione)</Label>
              <Input
              className="mb-3"
                name="num-pipe-curve"
                id="num-pipe-curve"
                value={selectedNumPipeCurve?.estrazione || ""}
                onChange={e => {
                  const numPipeCurveValue = selectedNumPipeCurve || {}
                  numPipeCurveValue["estrazione"] = e.target.value
                  setSelectedNumPipeCurve(
                    JSON.parse(JSON.stringify(numPipeCurveValue))
                  )
                }}
                type="number"
                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                min={0}
                max={50}
              />
              { selectedNumPipeCurve?.estrazione && (selectedNumPipeCurve?.estrazione < 0 || selectedNumPipeCurve?.estrazione > 50) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0 e 50
                    </Alert>
                  }
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
      <MiModal
        toggle={toggle}
        title={"ALERT"}
        show={showModal}
        handleBack={toggle}
        handleSave={handleMultipleSelectionTrigger}
        labelBtnBack={"No"}
        labelBtnSave={"Si"}
      >
        <Row>
          <Col>
            <p>
            Desideri adottare questa tipologia di condotto anche per gli altri tratti del sistema?

            </p>
          </Col>
        </Row>
      </MiModal>
    </Row>
  )
}

export default ConfiguratorStep7

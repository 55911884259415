import React, { useState, useEffect, useContext, useRef } from "react"
import {
  Col,
  Row,
  CardBody,
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardText,
  Input,
  Label,
  FormGroup,
  Alert
} from "reactstrap"

//Import Flatepicker
import Select from "react-select"
import { ConfiguratorContext } from "context/configuratorContext"
import { MiCardRadioButtons } from "components/Common/mi/MiCardRadioButtons"
import { MiModal } from "components/Common/mi/MiModal"


function ConfiguratorStep6({ setupData, step }) {
  const { configuratorData, setConfiguratorData, validations, setValidations, isKit } =
    useContext(ConfiguratorContext)

    const getPipeOptions = () => {
      return [
        { value: "EPE", label: "EPE" },
        // { value: "FLEX", label: "FLEX" },
        { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" },
      ]
    }
    const getTerminalOptions = () => {
      if (setupData && setupData["tipo_terminale"]) {
        const options = Object.keys(setupData["tipo_terminale"]).map(key => ({
          isDefault: isKit && key === "Parete con griglia antipioggia",
          value: key,
          label: key,
          text: key,
          img: `/imgs/${key}.png`,
        }))
        return options
      }
      return []
    }

  const defaultData =
    configuratorData && configuratorData["stepData" + step]
      ? configuratorData["stepData" + step]
      : {}
  const [showModal, setShowModal] = useState(false)
  const [tmpPipe, setTmpPipe] = useState(null)
  const [selectedPipe, setSelectedPipe] = useState(
    defaultData?.selectedPipe || { mandata: null, estrazione: null }
  )
  const [selectedPipeLength, setSelectedPipeLength] = useState(
    defaultData?.selectedPipeLength || { mandata: null, estrazione: null }
  )
  const [selectedNumPipeCurve, setSelectedNumPipeCurve] = useState(
    defaultData?.selectedNumPipeCurve || { mandata: null, estrazione: null }
  )
  const [selectedTerminal, setSelectedTerminal] = useState(
    defaultData?.selectedTerminal || { mandata: isKit ? getTerminalOptions().find(item => item.isDefault) : null, estrazione: isKit ? getTerminalOptions().find(item => item.isDefault) : null }
  )
  
  let inputValidation = useRef(null)
  const checkValidation = () => {
    inputValidation= [...document.querySelectorAll('input[type="number"]:invalid')]
    if (validations && validations['stepData' + step]) {
      validations['stepData' + step].isValid = selectedPipe?.mandata && selectedPipe?.estrazione &&
      selectedPipeLength?.mandata && selectedPipeLength?.estrazione &&
      selectedNumPipeCurve?.mandata && selectedNumPipeCurve?.estrazione &&
      (selectedTerminal?.mandata || selectedTerminal?.estrazione) && inputValidation.length === 0
      setValidations({ ...validations })
    } else {
      setValidations({ ...validations, ['stepData' + step]: { isValid: selectedPipe?.mandata && selectedPipe?.estrazione &&
        selectedPipeLength?.mandata && selectedPipeLength?.estrazione &&
        selectedNumPipeCurve?.mandata && selectedNumPipeCurve?.estrazione &&
        (selectedTerminal?.mandata || selectedTerminal?.estrazione) && inputValidation.length === 0} })
    }
  }

  const toggle = () => setShowModal(!showModal)

  const handleMultipleSelectionTrigger = () => {
    // console.log('handleMultipleSelectionTrigger', tmpPipe)
    // for current step
    const pipeValue = selectedPipe || {}
    pipeValue['mandata'] = tmpPipe
    pipeValue['estrazione'] = tmpPipe
    setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))

    // for next step
    if (!configuratorData.hasOwnProperty("stepData" + (Number(step) + 1))) {
      const data = configuratorData
      data["stepData" + (Number(step) + 1)] = {}
      setConfiguratorData({ ...configuratorData, ...data })
    }
    const dataNextStep = configuratorData["stepData" + (Number(step) + 1)]
    const nextPipeValue = dataNextStep?.selectedPipe || {}
    nextPipeValue['mandata'] = tmpPipe
    nextPipeValue['estrazione'] = tmpPipe
    if (!dataNextStep.hasOwnProperty('selectedPipe')) {
      dataNextStep['selectedPipe'] = {}
    }
    dataNextStep.selectedPipe = JSON.parse(JSON.stringify(nextPipeValue))
    configuratorData["stepData" + (Number(step) + 1)] = dataNextStep
    setConfiguratorData({ ...configuratorData })
    setTmpPipe(null)
    toggle()
  }

  

  useEffect(() => {
    if(isKit) {

      setSelectedPipe({ mandata: { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" }, estrazione: { value: "FLEX_ISOLATO", label: "FLEX ISOLATO" } }) 
    }
  }, [isKit])

  useEffect(() => {
    if (configuratorData && configuratorData["stepData" + step]) {
      configuratorData["stepData" + step].selectedPipe = selectedPipe
      configuratorData["stepData" + step].selectedPipeLength =
        selectedPipeLength
      configuratorData["stepData" + step].selectedNumPipeCurve =
        selectedNumPipeCurve
      configuratorData["stepData" + step].selectedTerminal = selectedTerminal

      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
  }, [selectedPipe, selectedPipeLength, selectedNumPipeCurve, selectedTerminal])

  return (
    <Row>
      {setupData && (
        <>
          <Col lg={6}>
            <Card className="no-shadow">
              <CardHeader className="p-3 rounded-xl">
                <CardImg
                  alt="Sample"
                  className="no-rounded"
                  src="/imgs/Step-5.gif"
                />
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5">STEP 5</CardTitle>
                <CardText>
                In questa schermata vengono selezionate le caratteristiche dei condotti principali (tratto di impianto compreso tra l'esterno dell'edificio e l'unità di ventilazione). Vengono inoltre scelti i terminali esterni di impianto.

                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <h4>UNITA' DI VENTILAZIONE - ESTERNO EDIFICIO</h4>
            <Card>
              <CardBody>
                <h3 className="">
                  Condotto di presa aria esterna{" "}
                  <span className="badge">
                    A
                  </span>
                </h3>
                <p className="">
                Condotto di collegamento compreso tra il terminale esterno di impianto di presa aria esterna e l'unità di ventilazione
                </p>
                <FormGroup className=" ">
                  <Label for="pipe-type" className="mb-1">Tipologia di canale (mandata)</Label>
                  <Select
                    placeholder="Seleziona..."
                    className="mb-3"
                    isDisabled={isKit}
                    name="pipe-type"
                    id="pipe-type"
                    value={selectedPipe?.mandata}
                    onChange={value => {
                      const pipeValue = selectedPipe || {}
                      pipeValue["mandata"] = value
                      setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))
                      setShowModal(true)
                      setTmpPipe(value)
                    }}
                    options={getPipeOptions()}
                  />
                </FormGroup>
                <FormGroup className=" ">
                  <Label for="pipe-length" className="mb-1">Lunghezza tubo mandata (m)</Label>
                  <Input
                    className="mb-3"
                    name="pipe-length"
                    id="pipe-length"
                    value={selectedPipeLength?.mandata || ""}
                    onChange={e => {
                      const pipeLengthValue = selectedPipeLength || {}
                      pipeLengthValue["mandata"] = e.target.value
                      setSelectedPipeLength(
                        JSON.parse(JSON.stringify(pipeLengthValue))
                      )
                    }}
                    type="number"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    step={0.1}
                    min={0.1}
                    max={100}
                  />
                  { (selectedPipeLength?.mandata && (selectedPipeLength?.mandata < 0.1 || selectedPipeLength?.mandata > 100.0)) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0.1 e 100.0
                    </Alert>
                  }
                </FormGroup>
                <FormGroup className="">
                  <Label for="pipe-length" className="mb-1">Numero curve tratte (mandata)</Label>
                  <Input
                    className="mb-3"
                    name="num-pipe-curve"
                    id="num-pipe-curve"
                    value={selectedNumPipeCurve?.mandata || ""}
                    onChange={e => {
                      const numPipeCurveValue = selectedNumPipeCurve || {}
                      numPipeCurveValue["mandata"] = e.target.value
                      setSelectedNumPipeCurve(
                        JSON.parse(JSON.stringify(numPipeCurveValue))
                      )
                    }}
                    type="number"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    min={0}
                    max={50}
                  />
                  { selectedNumPipeCurve?.mandata && (selectedNumPipeCurve?.mandata < 0 || selectedNumPipeCurve?.mandata > 50) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0 e 50
                    </Alert>
                  }
                </FormGroup>
                {selectedTerminal.estrazione?.value !== "Terminale doppio" && (
                  <FormGroup className="mb-2" style={{
                    marginLeft: '-0.5em'
                  }}>
                    <MiCardRadioButtons
                      disabled={isKit}
                      hideChoiceWhenDisabled={isKit}
                      defaultValue={selectedTerminal?.mandata}
                      optionStyle={{
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        maxHeight: 150,
                        width: 160,
                      }}
                      onChange={value => {
                        const terminalValue = selectedTerminal || {}
                        terminalValue["mandata"] = value
                        if (value?.value === "Terminale doppio"){
                          terminalValue["estrazione"] = null
                        }
                        setSelectedTerminal(
                          JSON.parse(JSON.stringify(terminalValue))
                        )
                      }}
                      data={{
                        title: "Tipologia terminale (mandata)",
                        items: getTerminalOptions(),
                      }}
                    ></MiCardRadioButtons>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h3 className="">
                  Condotto di espulsione aria esausta{" "}
                  <span className="badge">
                    B
                  </span>
                </h3>
                <p className="">
                Condotto di collegamento compreso tra il terminale esterno di impianto di espulsione aria esausta e l'unità di ventilazione

                </p>
                <FormGroup className="mb-3 ">
                  <Label for="pipe-type" className="mb-1">Tipologia tubo (estrazione)</Label>
                  <Select
                    placeholder="Seleziona..."
                    className="mb-3"
                    isDisabled={isKit}
                    name="pipe-type"
                    id="pipe-type"
                    value={selectedPipe?.estrazione}
                    onChange={value => {
                      const pipeValue = selectedPipe || {}
                      pipeValue["estrazione"] = value
                      setSelectedPipe(JSON.parse(JSON.stringify(pipeValue)))
                      setShowModal(true)
                      setTmpPipe(value)
                    }}
                    options={getPipeOptions()}
                  />
                </FormGroup>
                <FormGroup className="">
                  <Label for="pipe-length" className="mb-1">Lunghezza tubo estrazione (m)</Label>
                  <Input
                    className="mb-3"
                    name="pipe-length"
                    id="pipe-length"
                    value={selectedPipeLength?.estrazione || ""}
                    onChange={e => {
                      const pipeLengthValue = selectedPipeLength || {}
                      pipeLengthValue["estrazione"] = e.target.value
                      setSelectedPipeLength(
                        JSON.parse(JSON.stringify(pipeLengthValue))
                      )
                    }}
                    type="number"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    min={0.1}
                    step={0.1}
                    max={100}
                  />
                  { selectedPipeLength?.estrazione && (selectedPipeLength?.estrazione < 0.1 || selectedPipeLength?.estrazione > 100) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0.1 e 100
                    </Alert>
                  }
                </FormGroup>
                <FormGroup className="">
                  <Label for="pipe-length" className="mb-1">
                    Numero curve tratte (estrazione)
                  </Label>
                  <Input
                    className="mb-3"
                    name="num-pipe-curve"
                    id="num-pipe-curve"
                    value={selectedNumPipeCurve?.estrazione || ""}
                    onChange={e => {
                      const numPipeCurveValue = selectedNumPipeCurve || {}
                      numPipeCurveValue["estrazione"] = e.target.value
                      setSelectedNumPipeCurve(
                        JSON.parse(JSON.stringify(numPipeCurveValue))
                      )
                    }}
                    type="number"
                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                    min={0}
                    max={50}
                  />
                  { selectedNumPipeCurve?.estrazione && (selectedNumPipeCurve?.estrazione < 0 || selectedNumPipeCurve?.estrazione > 50) &&
                    <Alert 
                    color="warning" 
                    className='mt-3'
                    >
                      Inserisci un valore compreso tra 0 e 50
                    </Alert>
                  }
                  
                </FormGroup>
                {selectedTerminal.mandata?.value !== "Terminale doppio" && (
                  <FormGroup className="mb-2" style={{
                    marginLeft: '-0.5em'
                  }}>
                    
                    <MiCardRadioButtons
                      disabled={isKit}
                      defaultValue={selectedTerminal?.estrazione}
                      hideChoiceWhenDisabled={isKit}
                      optionStyle={{
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        maxHeight: 150,
                        width: 160,
                      }}
                      onChange={value => {
                        const terminalValue = selectedTerminal || {}
                        terminalValue["estrazione"] = value
                        if (value?.value === "Terminale doppio"){
                          terminalValue["mandata"] = null
                        }
                        setSelectedTerminal(
                          JSON.parse(JSON.stringify(terminalValue))
                        )
                      }}
                      data={{
                        title: "Tipologia terminale (estrazione)",
                        items: getTerminalOptions(),
                      }}
                    ></MiCardRadioButtons>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
          </Col>
        </>
      )}
      <MiModal
        toggle={toggle}
        title={"ALERT"}
        show={showModal}
        handleBack={toggle}
        handleSave={handleMultipleSelectionTrigger}
        labelBtnBack={"No"}
        labelBtnSave={"Si"}
      >
        <Row>
          <Col>
            <p>
            Desideri adottare questa tipologia di condotto anche per gli altri tratti del sistema?
            </p>
          </Col>
        </Row>
      </MiModal>
    </Row>
  )
}

export default ConfiguratorStep6
